<template>
  <div class="app-container">
    <div class="banner flex items-center justify-center"></div>

    <el-tabs v-model="activeName">
      <!-- 1，公司介绍 -->
      <el-tab-pane name="first">
        <span slot="label">
          <div class="tab-label">
            <img src="~@/assets/learnNatux/logo1.png" alt="" />公司介绍
          </div>
        </span>

        <div class="tab-content company-intro">
          <img src="~@/assets/learnNatux/title1.png" alt="" class="title-img" />
          <div class="intro-wrapper flex">
            <div class="intro-left flex flex-column justify-center">
              <div class="left-item">
                <img src="~@/assets/learnNatux/intro-decoration.png" alt="" />
                {{ introTexts[0] }}
              </div>
              <div class="left-item">
                <img src="~@/assets/learnNatux/intro-decoration.png" alt="" />{{
                  introTexts[1]
                }}
              </div>
            </div>
            <div class="intro-right"></div>
          </div>
        </div>
      </el-tab-pane>

      <!-- 2.核心团队成员 -->
      <el-tab-pane name="second">
        <span slot="label">
          <div class="tab-label">
            <img src="~@/assets/learnNatux/logo2.png" alt="" />核心团队
          </div>
        </span>

        <div class="tab-content group">
          <img src="~@/assets/learnNatux/title2.png" alt="" class="title-img" />
          <div class="member-wrapper">
            <div
              class="member-item-wrapper"
              v-for="(item, index) in members"
              :key="index"
            >
              <div class="member-item flex flex-column items-center">
                <div class="photo"></div>
                <div class="name">{{ item.name }}</div>
                <div class="engName">{{ item.engName }}</div>
                <div class="job">{{ item.job }}</div>
                <div class="decoration"></div>
                <div class="description">{{ item.description }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>

      <!-- 3.发展历程 -->
      <el-tab-pane name="third">
        <span slot="label">
          <div class="tab-label">
            <img src="~@/assets/learnNatux/logo3.png" alt="" />发展历程
          </div>
        </span>

        <div class="tab-content history">
          <img src="~@/assets/learnNatux/title3.png" alt="" class="title-img" />
        </div>
      </el-tab-pane>

      <!-- 4.所获荣誉 -->
      <el-tab-pane name="fourth">
        <span slot="label">
          <div class="tab-label">
            <img src="~@/assets/learnNatux/logo4.png" alt="" />所获荣誉
          </div>
        </span>

        <div class="tab-content awards">
          <img src="~@/assets/learnNatux/title4.png" alt="" class="title-img" />
          <div class="awards-wrapper">
            <div
              class="awards-item-wrapper"
              v-for="(item, index) in awards"
              :key="index"
            >
              <div class="awards-item flex flex-column items-center">
                <div class="photo"></div>
                <div class="name">{{ item.name }}</div>
                <div class="date">{{ item.date }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>

      <!-- 5.组织架构 -->
      <el-tab-pane name="fifth">
        <span slot="label">
          <div class="tab-label">
            <img src="~@/assets/learnNatux/logo5.png" alt="" />组织架构
          </div>
        </span>

        <div class="tab-content group-frame">
          <img src="~@/assets/learnNatux/title5.png" alt="" class="title-img" />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "first",
      introTexts: [
        "西安纳图智能科技有限公司是一家专注于自主创新与研发智能生物医疗产品的公司，致力于通过高品质的产品和优质的服务，为全球生物医疗行业提供更加精确化、智能化的整体解决方案。",
        "纳图始终坚持着用科技助力医疗，秉承生物+智能科技融合的产品服务于人民的理念，成立至今，为客户创造了巨大的价值。",
      ],
      members: [
        {
          name: "柴治成",
          engName: "Zhicheng Chai",
          job: "前端开发工程师",
          description:
            "描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述",
          photo: "",
        },
        {
          name: "柴治成",
          engName: "zhicheng Chai",
          job: "前端开发工程师",
          description:
            "描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述",
          photo: "",
        },
        {
          name: "柴治成",
          engName: "zhicheng Chai",
          job: "前端开发工程师",
          description:
            "描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述",
          photo: "",
        },
        {
          name: "柴治成",
          engName: "zhicheng Chai",
          job: "前端开发工程师",
          description:
            "描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述",
          photo: "",
        },
        {
          name: "柴治成",
          engName: "zhicheng Chai",
          job: "前端开发工程师",
          description:
            "描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述",
          photo: "",
        },
        {
          name: "柴治成",
          engName: "zhicheng Chai",
          job: "前端开发工程师",
          description:
            "描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述",
          photo: "",
        },
      ],
      awards: [
        {
          name: "证书名称",
          date: "2021-05-01",
          img: "",
        },
        {
          name: "证书名称",
          date: "2021-05-01",
          img: "",
        },
        {
          name: "证书名称",
          date: "2021-05-01",
          img: "",
        },
        {
          name: "证书名称",
          date: "2021-05-01",
          img: "",
        },
        {
          name: "证书名称",
          date: "2021-05-01",
          img: "",
        },
        {
          name: "证书名称",
          date: "2021-05-01",
          img: "",
        },
        {
          name: "证书名称",
          date: "2021-05-01",
          img: "",
        },
        {
          name: "证书名称",
          date: "2021-05-01",
          img: "",
        },
      ],
    };
  },
  watch: {
    $route(to) {
      console.log("324234", this.$route.query.p);
      this.$nextTick(() => {
        switch (Number(this.$route.query.p)) {
          case 0:
            this.activeName = "first";
            break;
          case 1:
            this.activeName = "second";
            break;
          case 2:
            this.activeName = "third";
            break;
          case 3:
            this.activeName = "fourth";
            break;
          case 4:
            this.activeName = "fifth";
            break;

          default:
            break;
        }
      });
    },
  },

  methods: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
@function vw($px) {
  @return ($px / 1920) * 100vw;
}

.banner {
  height: vw(516);
  background-image: url("~@/assets/learnNatux/banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.tab-content {
  //   height: vw(755);
  margin-top: vw(99);
}

.company-intro {
  .intro-wrapper {
    margin-top: vw(50);
    padding: 0 vw(396) vw(161) vw(389);
    .intro-left {
      width: vw(533);
      color: #333333;
      line-height: vw(30);
      .left-item {
        display: flex;
        text-align: left;
        img {
          width: vw(14);
          height: vw(16);
          margin-right: vw(9);
          margin-top: vw(8);
        }
        &:first-child {
          margin-bottom: vw(50);
        }
      }
    }

    .intro-right {
      width: vw(443);
      height: vw(368);
      background: url("~@/assets/learnNatux/intro-right.png") no-repeat center;
      background-size: contain;
      margin-left: vw(136);
    }
  }
}

.group {
  .member-wrapper {
    margin-top: vw(60);
    padding: 0 vw(220) vw(83) vw(223);
    display: flex;
    flex-wrap: wrap;
    .member-item-wrapper {
      width: 33.33%;
      display: flex;
      margin-bottom: vw(45);
      //   justify-content: center;
      .member-item {
        height: vw(734);
        width: vw(380);

        .photo {
          width: vw(380);
          height: vw(470);
          margin-bottom: vw(43);
          box-shadow: 0px 0px 20px 0px rgba(0, 33, 67, 0.09);
        }
        .name {
          font-size: vw(16);
          font-weight: bold;
          color: #0b325a;
          margin-bottom: vw(11);
        }
        .engName {
          font-size: vw(16);
          font-weight: bold;
          color: #0b325a;
          margin-bottom: vw(17);
        }
        .job {
          color: #666666;
          font-size: vw(14);
          margin-bottom: vw(19);
        }
        .decoration {
          width: vw(20);
          height: vw(4);
          background: #ff9a4f;
          border-radius: 2px;
          margin-bottom: vw(18);
        }
        .description {
          font-size: vw(14);
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: vw(30);
        }
      }
      &:nth-child(3n + 1) {
        justify-content: flex-start;
      }
      &:nth-child(3n + 2) {
        justify-content: center;
      }
      &:nth-child(3n + 3) {
        justify-content: flex-end;
      }
    }
  }
}

.history {
  height: vw(500);
}

.awards {
  .awards-wrapper {
    margin-top: vw(60);
    padding: 0 vw(222) vw(83) vw(221);
    display: flex;
    flex-wrap: wrap;
    .awards-item-wrapper {
      width: 20%;
      display: flex;
      margin-bottom: vw(45);
      justify-content: center;
      .awards-item {
        height: vw(358);
        width: vw(235);

        .photo {
          width: vw(235);
          height: vw(290);
          margin-bottom: vw(27);
          box-shadow: 0px 0px 20px 0px rgba(0, 33, 67, 0.09);
        }
        .name {
          font-size: vw(16);
          font-weight: bold;
          color: #333;
          margin-bottom: vw(11);
        }

        .date {
          color: #666666;
          font-size: vw(12);
        }
      }
      &:nth-child(5n + 1) {
        justify-content: flex-start;
      }
      &:nth-child(5n + 5) {
        justify-content: flex-end;
      }
    }
  }
}

.group-frame {
  height: vw(501);
}

.tab-label {
  display: flex;
  align-items: center;
  img {
    width: vw(40);
    height: vw(40);
    margin-right: vw(10);
  }
}

::v-deep .el-tabs__header {
  margin-bottom: 0;
  .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
    align-items: center;
    height: vw(80);
    background: #f9f9f9;
    .el-tabs__nav {
      height: 100%;
      .el-tabs__active-bar {
        height: vw(4);
        background-color: rgba(56, 154, 255, 1);
      }
      .el-tabs__item {
        padding: 0 vw(60);
        height: 100%;
        line-height: vw(80);
        color: rgba(51, 51, 51, 1);
        font-weight: bold;
        font-size: vw(16);
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
</style>